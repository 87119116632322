<template>
  <div>
    <h4 class="bold mb-4">Mis pendientes</h4>
    <div class="d-flex align-center">
      <span class="material-symbols-rounded"> description </span>
      <span class="bold h5 ml-3">Simulaciones</span>
    </div>

    <div v-if="loading">
      <v-skeleton-loader
        v-for="index in 3"
        :key="index"
        class="rounded-lg mb-2 mt-4"
        type="card"
        height="210px"
      >
      </v-skeleton-loader>
    </div>

    <div v-else-if="!Cotizaciones.length > 0" class="transparent">
      <v-img
        class="text-center mx-auto"
        max-width="250px"
        width="250"
        src="@/assets/sin_cotizaciones-01.svg"
      />
      <h3 class="global-pendentText text-center mt-6">
        No se encontró simulaciones
      </h3>
    </div>

    <div v-else>
      <div v-for="(item, i) in Cotizaciones" :key="i" class="py-3">
        <PendingCard :loading="item.loading">
          <template v-slot:number>
            <p>Nº Simulación {{ item.numero_cotizacion }}</p>
          </template>
          <template v-slot:doc-description>Tipo documento</template>
          <template v-slot:name>
            <p>
              {{ item.cliente.slice(0, 18)
              }}{{ item.cliente.length > 18 ? "..." : "" }}
            </p>
          </template>
          <template v-slot:description>
            <p v-if="item?.marca_descripcion" class="text-center">
              <b>
                {{
                  `${item?.marca_descripcion ?? ""} ${
                    item?.modelo_descripcion ?? ""
                  }`
                }}
              </b>
            </p>
            <p v-else>
              <b>
                {{ item?.categoria_descripcion ?? "" }}
              </b>
            </p>
          </template>
          <template v-slot:doc-type>
            <span>
              {{ item.tipo_documento }}
            </span>
          </template>
          <template v-slot:doc-number>
            <span v-if="isChile">
              {{ item.numero_documento | rutFormat }}
            </span>
            <span v-else>
              {{ item.numero_documento }}
            </span>
          </template>
          <template v-slot:detail>
            <h5 class="caption font-weight-bold">Retomar desde:</h5>
            <v-chip small class="mt-1">
              <v-icon>{{ `mdi-${getEtapa(item)?.icon}` }}</v-icon>
              {{ getEtapa(item)?.value ?? "Desconocido" }}
            </v-chip>
          </template>
          <template v-slot:button>
            <v-btn
              @click="onPress(item)"
              rounded
              block
              color="primary"
              class="text-none mt-2"
              :loading="item.loading"
            >
              Terminar simulación
            </v-btn>
          </template>
        </PendingCard>
      </div>

      <p class="text-center">
        <v-btn
          color="primary"
          text
          @click="$router.push({ name: 'quotes' })"
          class="text-none"
        >
          Ver más simulaciones <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
      </p>
    </div>
  </div>
</template>
<script>
import PendingCard from "@/components/Pages-Components/Dashboard/SideBarCards.vue";
import CountryMixin from "@/Mixins/CountryMixin.js";
import { mapGetters } from "vuex";

import { estapaCotizacion } from "@/helpers/cotizacion.js";

export default {
  name: "PendingQuotes",
  mixins: [CountryMixin],
  props: {
    AccionButton: { type: Function },
    loading: {
      type: Boolean,
      default: false,
      description: "Cargando cotizaciones.",
    },
  },
  components: {
    PendingCard,
  },
  methods: {
    onPress(item) {
      item.loading = true;
      this.$emit("action-button", item);
    },
    getEtapa(item = null) {
      return estapaCotizacion(item);
    },
  },
  computed: {
    ...mapGetters("Quot", ["Cotizaciones"]),
  },
};
</script>
