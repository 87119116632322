<template>
  <div>
    <v-card flat class="pa-10">
      <v-card-title primary-title class="font-weight-bold">
        ¿Con cúal documento deseas cotizar?
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form ref="searchForm" class="px-4" v-model="valid">
          <v-radio-group
            v-model="form.tipo_documento"
            row
            mandatory
            :rules="[(v) => !!v || 'Debe seleccionar un tipo de documento.']"
          >
            <v-radio
              v-for="item in country?.docType ?? []"
              :key="item._id"
              :label="item.descripcion"
              :value="item.codigo"
            ></v-radio>
          </v-radio-group>

          <h5 class="mb-3 mt-6 text-h6 font-weight-bold">
            Ingresa el {{ form.tipo_documento }}
          </h5>
          <!-- BUSCADOR -->
          <!-- Buscador de Chile -->
          <v-text-field
            v-if="isChile"
            :placeholder="placeholder"
            append-icon="mdi-magnify"
            v-model="form.numero_documento"
            :rules="[rules.required, rules.rut, rules.isValid]"
            persistent-placeholder
            outlined
            dense
            v-mask="rutMask"
            name="buscar_cli"
            class="rounded-lg"
          >
          </v-text-field>
          <!-- Buscador normal para el resto -->
          <v-text-field
            v-else
            :placeholder="'Escriba el número de documento...'"
            append-icon="mdi-magnify"
            v-model="form.numero_documento"
            :rules="[rules.required]"
            persistent-placeholder
            outlined
            dense
            v-mask="onlyNumberMask"
            name="buscar_cli"
            class="rounded-lg"
          >
          </v-text-field>
          <v-slide-y-reverse-transition mode="out-in">
            <p class="mb-0 text-center" v-if="getUserInfo?.cliente">
              <v-btn
                color="primary"
                small
                text
                class="text-none"
                @click="
                  handlerSearchUser(
                    getUserInfo?.cliente?.numero_documento ?? '',
                    getUserInfo?.cliente?.tipo_documento
                  )
                "
                >Ver de nuevo al cliente
                {{ getUserInfo?.cliente?.tipo_documento ?? "" }}
                {{ getUserInfo?.cliente?.numero_documento ?? "" }}
                <v-icon right color="primary"
                  >mdi-arrow-u-right-top</v-icon
                ></v-btn
              >
            </p>
          </v-slide-y-reverse-transition>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-12">
        <v-btn
          color="primary"
          class="text-none"
          block
          rounded
          large
          @click="handlerSearchUser()"
          :loading="loading"
          >Buscar</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- MODAL NUEVO CLIENTE -->
    <v-dialog v-model="dialog" width="40%" attach="#main-container">
      <v-card class="py-4" id="client_reg_modal">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="h5 semiBold">{{
              clientStatus == 0
                ? "Registro de nuevo cliente"
                : "Información del cliente"
            }}</span>
            <v-btn icon color="error" @click="handlerCloseDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-center mt-6 justify-center">
            <v-icon x-large>mdi-account-search-outline</v-icon>
            <span class="p ml-6" v-if="clientStatus == 0"
              >El
              <span class="semiBold"
                >{{ form.tipo_documento }} {{ form.numero_documento }}</span
              >
              no ha sido registrado en el sistema
            </span>
            <p class="p ml-6" v-else>
              <span class="subtitle-2"
                >¡El cliente
                <span class="semiBold">{{ clientFullName }}</span>
                ha sido encontrado!</span
              >
              <br />
              <br />
              El sistema de ventas esta solicitando que complete la información
              de este cliente y/o acepte las políticas de privacidad de este
              canal para poder continuar.
            </p>
          </div>
          <div class="d-flex flex-column justify-center align-center my-6">
            <v-btn
              color="primary"
              @click="handlerCloseDialog()"
              width="214"
              height="41"
              class="text-none"
              outlined
              rounded
              >Volver a buscar</v-btn
            >
            <v-btn
              color="primary"
              class="text-none mt-4"
              width="214"
              height="41"
              id="reg_client_button"
              rounded
              @click="addUser()"
              >{{
                clientStatus == 0 ? "Registrar cliente" : "Editar cliente"
              }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { validarRutFormat, validarRut } from "@/store/resources/validate.js";
import { rutMask, onlyNumberMask } from "@/helpers/mask.js";
import { mapActions, mapGetters } from "vuex";
import CountryMixin from "@/Mixins/CountryMixin.js";
export default {
  name: "FindUserForm",
  mixins: [CountryMixin],
  data() {
    return {
      valid: false,
      form: {
        numero_documento: "",
        tipo_documento: "",
      },
      placeholder: "Ej: 15686578-8",
      label: "Buscar cliente",
      loading: false,
      dialog: false,
      clientStatus: 0,
      clientFullName: "",
      rules: {
        required: (value) =>
          !!value || "Escriba el número de documento para comenzar a buscar...",

        rut: (value) => validarRutFormat(value) || "Formato de RUT invalido",
        isValid: (value) =>
          validarRut(value) || "No se ha logrado reconocer el RUT",
      },
    };
  },
  computed: {
    ...mapGetters("Users", ["getUserInfo"]),
    rutMask: () => rutMask,
    onlyNumberMask: () => onlyNumberMask(30),
  },
  methods: {
    ...mapActions("Users", ["findUser"]),

    handlerSearchUser(docNumb = "", docType = "") {
      if ((docNumb && docType) || this.$refs.searchForm.validate()) {
        this.loading = true;
        this.findUser({
          document_number:
            docNumb || this.form.numero_documento.replace(/[^A-Z0-9]/gi, ""),
          document_type: docType || this.form.tipo_documento,
        })
          .then(({ result, client }) => {
            const clientInfo = client.cliente;
            if (client) {
              this.clientFullName = `${clientInfo?.nombre} ${clientInfo?.primer_apellido} ${clientInfo?.segundo_apellido}`;
            }
            if (result.codigo_accion == 1) {
              this.clientStatus = 1;
              this.dialog = true;
            } else {
              const { numero_documento, tipo_documento } = clientInfo;
              this.$router.push({
                name: "UserDetail",
                params: {
                  document_number: numero_documento,
                  document_type: tipo_documento,
                },
              });
            }
          })
          .catch(() => {
            this.dialog = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    handlerCloseDialog() {
      this.dialog = false;
      this.clientFullName = "";
      this.clientStatus = 0;
    },

    addUser() {
      this.$router.push({
        name: "NewEditUser",
        params: {
          document_number: this.form.numero_documento.replace(
            /[^A-Z0-9]/gi,
            ""
          ),
          document_type: this.form.tipo_documento,
        },
      });
    },
  },
};
</script>
