<template>
  <div class="pl-7 pr-10 py-10">
    <PendingQuotes
      v-if="showQuotes"
      :loading="loading.quotes"
      @action-button="Retomar($event)"
    />
    <PendingDocuments
      v-if="showDocs"
      :loading="loading.documents"
      @action-button="selectDocument"
    />
  </div>
</template>
<script>
import PendingQuotes from "@/components/Pages-Components/Dashboard/Quotes.vue";
import PendingDocuments from "@/components/Pages-Components/Dashboard/Documents.vue";
import { resumeQuote } from "@/Mixins/resumeQuote.js";
import { mapActions } from "vuex";

export default {
  name: "Pendientes",
  mixins: [resumeQuote],
  props: {
    showDocs: {
      type: Boolean,
      default: true,
    },
    showQuotes: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PendingQuotes,
    PendingDocuments,
  },
  watch: {
    // Documentstatus() {},
    channel(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setup();
      }
    },
  },
  data: () => ({
    loading: {
      quotes: false,
      documents: false,
    },
  }),
  created() {
    this.setup();
  },
  computed: {
    channel() {
      return this.$store.getters.getChannelSelected?.id;
    },
  },
  methods: {
    ...mapActions("Documents", ["GetDocs"]),
    ...mapActions("Quot", ["GetCotizaciones"]),

    async setup() {
      if (this.showQuotes) {
        this.setQuotes();
      }
      if (this.showDocs) {
        this.setDocuments();
      }
    },
    // === Establecer las cotizaciones pendientes ===
    setQuotes() {
      this.loading.quotes = true;
      return new Promise((resolve) => {
        this.GetCotizaciones()
          .catch((error) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: error.info,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading.quotes = false));
        resolve("ok");
      });
    },
    // === Establecer los documentos pendientes ===
    setDocuments() {
      this.loading.documents = true;
      return new Promise((resolve) => {
        this.GetDocs({
          limit: 3,
          estados_documentos: "PEN,REC",
          usuario: true,
        }).finally(() => (this.loading.documents = false));
        resolve("ok");
      });
    },

    selectDocument(item) {
      this.$router.push({
        name: "documents",
        query: { propuesta: item?.numero_propuesta },
      });
    },
  },
};
</script>
